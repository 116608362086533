/*#imgBerandaBrand {
  margin: 0px;
  padding: 0px;
  color:white;
  width:65px
}

#beranda li.list-group-item a {
  color: white;
  text-decoration: none;
}

#beranda li.list-group-item {
  background: transparent;
  border-bottom-color: white;
}*/

#beranda img {
    width: 3rem;
    height: auto;
}

#centerMap {
  margin: 0 auto
}
a.linkList:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
  color:#212529;
}
a.linkList {
  color:#212529;
}
.bm-menu {
  width: 100%;
}
.bm-item {
  height: 100%;
}
#navTop {
  margin-left: 4.5rem;
  padding-left: 0;
}
button#react-burger-menu-btn {
  width: 4.5rem!important;
  background: #ffc107!important;
  height: 4.5rem!important;
  border: none;
}
button#react-burger-menu-btn::before {
  content: "☰";
  color: #000;
  height: 100%;
  font-size: 2rem;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
}
.bm-menu-wrap {
  left: 0!important;
}
.bm-menu-wrap {
  left: 0!important;
}